import { useState } from 'react'
import { LoginUserDTO } from '~/domain/user/dto/LoginUserDTO'
import { getAvatarUrl } from '~/domain/user/services/UserDTOService'

export interface Props {
  user: LoginUserDTO
}

export const useLoginUser = (props: Props) => {
  const { user } = props
  const [isOpen, setIsOpen] = useState(false)
  const onClickUser = () => {
    setIsOpen(!isOpen)
  }
  const onClickLogout = () => { }

  return {
    user,
    isOpen,
    avatarUrl: getAvatarUrl(user),
    onClickUser,
    onClickLogout,
  }
}
