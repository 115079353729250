import { FC } from 'react';
import { Link, Form } from '@remix-run/react';
import { Avatar } from '~/application/components/Avatar/Avatar';
import { useLoginUser, Props } from './useLoginUser';
import styles from './LoginUser.module.css';

export const LoginUser: FC<Props> = (props) => {
  const { user, avatarUrl, isOpen, onClickUser, onClickLogout } =
  useLoginUser(props);

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.user} onClick={onClickUser}>
          <Avatar src={avatarUrl} name={user.name} />
        </div>
        {isOpen &&
        <div className={styles.box}>
            <div className={styles.inner}>
              <Link to={`/dashboard`} className={styles.item}>
                ダッシュボード
              </Link>
              <Link to={`/feeds`} className={styles.item}>
                フィード
              </Link>
              <span className={styles.item} onClick={onClickLogout}>
                <Form method="post" action="/logout">
                  <button>ログアウト</button>
                </Form>
              </span>
            </div>
          </div>}

      </div>
    </div>);

};